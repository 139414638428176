<template>
    <div class="message">
        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'15px'"/>
            </div>
            <div class="title">Messages</div>

            <div class="icon search">
                <search :size="'15px'" :style="{'height': '15px'}"/>
            </div>
        </div>
        
        <div class="main">
            <swiper ref="mySwiper" class="swiper" :options="swiperOptions" @slideChange="slidemoved()">
                <swiper-slide class="slide">

                    <div class="loading" v-if="chats_loading">
                        <div class="spin"></div>
                    </div>

                    <div v-else>
                        <div class="info" v-if="chats.length == 0">
                            <chat :size="'50px'"/>
                            Your chat list is currently empty
                        </div>
                        <chatlist v-for="chat in chats" :key="chat.chat_id" :chat="chat"/>
                    </div>
                </swiper-slide>
                <swiper-slide class="slide">
                    <div class="info">
                        <group :size="'50px'"/>
                        Group chat will be available in the next update
                    </div>
                </swiper-slide>
                <swiper-slide class="slide">
                    <div class="loading" v-if="chat_requests_loading">
                        <div class="spin"></div>
                    </div>
                    <div v-else>
                        <div class="info" v-if="chat_requests.length == 0">
                            <add_user :size="'50px'"/>
                            Your message requests is currently empty
                        </div>
                        <request v-for="(chat_request, i) in chat_requests" 
                            :key="chat_request.chat_id" :request="chat_request"
                            @chat_approved="chat_approved(i)"/>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        
        <div class="menu">
            <div class="item" :class="[c == 0 ? 'a' : '']" @click="slide_to(0)">
                <chat :size="'20px'"/>
                Chats
            </div>
            <div class="item" :class="[c == 1 ? 'a' : '']" @click="slide_to(1)">
                <group :size="'20px'"/>
                Groups
            </div>
            <div class="item" :class="[c == 2 ? 'a' : '']" @click="slide_to(2)">
                <add_user :size="'20px'"/>
                Requests · {{ this.$root.$data.chat_requests > 0 ? this.$root.$data.chat_requests : ''}}
            </div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import back from '../../icons/back.vue'
import search from '../../icons/search.vue'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import chatlist from '../../components/message/chatlist.vue'
import request from '../../components/message/request.vue'


import chat from '../../icons/chat.vue'
import group from '../../icons/users.vue'
import add_user from '../../icons/add_user.vue'


export default {
    components: {
        back,
        search,
        chatlist,
        request,
        
        Swiper, SwiperSlide,

        chat, group, add_user
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        },
        chat_requests_no() {
            return this.$root.$data.chat_requests
        }
    },
    watch: {
        chat_requests_no() {
            this.fetch_requests()
        }
    },
    data() {
        return {
            c: 0,
            swiperOptions: {
                resistanceRatio: 0,
                autoHeight: true
            },
            chats: [],
            chats_loading: false,
            
            
            chat_requests: [],
            chat_request_username: '',
            chat_requests_loading: false
        }
    },
    activated() {
        setTimeout(() => {
            let q = this.$route.query

            if (q['view']) {
                this.slide_to(parseInt(q['view']))
            }
            if (q['username']) {
                this.chat_request_username = q['username']
            }

            this.$root.$data.message_notification = false
            this.init_chat()
            
            setTimeout(() => {
                this.$router.replace({'query': null});
            }, 1000);
        }, 10);
    },
    mounted() {
        
        let q = this.$route.query

        if (q['view']) {
            this.slide_to(parseInt(q['view']))
        }
        if (q['username']) {
            this.chat_request_username = q['username']
        }
        
        this.$root.$data.message_notification = false
        this.init_chat()
        
        setTimeout(() => {
            this.$router.replace({'query': null});
        }, 1000);
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        init_chat() {
            if (this.chats_loading) {
                return
            }

            this.chats_loading = true

            db.collection('chats').orderBy('updated_at', 'desc').get().then(data => {
                if (data.length == 0) {

                    this.$http.post('/chats')
                    .then(data => {
                        let r = data.data
                        if (r.success) {
                            for(let x of r.chats) {
                                let chat = {
                                    'chat_id': x.chat_id,
                                    'date': x.created,
                                    'updated_at': new Date(x.updated_at).getTime(),
                                    'initiator': x.initiator,
                                    'user1': x.user1,
                                    'user2': x.user2,
                                    'is_approved': true,
                                    'declined': false,
                                    'declined_at': '0000-00-00',
                                    'count': r.count,
                                    'user': x.initiator == this.$root.$data.username ? x.user2 : x.user1,
                                    'new_messages': 0
                                }
                                db.collection('chats').add(chat)
                                
                                db.collection('chat_users').add(x.chat_user)
                                
                                this.chats.push(chat)
                            }
                            this.chats_loading = false
                        }
                    })
                } else {
                    this.chats = data
                    this.chats_loading = false
                }
            })

            
            this.fetch_requests()
        },


        slide_to(where) {
            this.swiper.slideTo(where)
        },
        slidemoved() {
            this.c = this.swiper.activeIndex
        },

        fetch_requests() {
            if (this.chat_requests_loading) {
                return
            }

            this.chat_requests_loading = this.chat_requests.length == 0 ? true : false

            let form = new FormData
            form.append('username', this.chat_request_username)

            this.$http.post('/chat/requests', form)
            .then(data => {
                let r = data.data
                if (r.success) {

                    this.chat_requests = []

                    if (r.chat) {
                        r.chat[0]['active'] = true
                        this.chat_requests.unshift(r.chat[0])
                    }

                    for (let x of r.chats) {
                        if (x.initiator != this.chat_request_username) {
                            this.chat_requests.push(x)
                        }
                    }

                    this.chat_request_username = ''
                    this.chat_requests_loading = false
                }

            })
        },
        chat_approved(i) {
            this.$root.$data.chat_requests--
            this.chat_requests.splice(i, 1)
            this.slide_to(0)
        }
    }
}
</script>

<style scoped>
    .message {
        background-color: white;
    }
    .head {
        /* background-color: var(--main); */
        /* padding: 15px; */
        /* padding-bottom: 0px; */
        border-bottom: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* gap: 20px; */
        fill: var(--main);
    }
    .icon {
        width: 50px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title {
        font-weight: 600;
        /* font-size: 18px; */
        /* color: white; */
    }
    .search {
        /* margin-left: auto; */
    }


    .menu {
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
        align-items: center;
        /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
        background-color: white;
        border-top: 1px solid #ddd;
        position: relative;
        z-index: 2;
        /* padding: 0px 20px; */
        box-sizing: border-box;
    }
    .item {
        text-align: center;
        fill: #555;
        padding: 10px 0px;
        position: relative;
        
        font-size: 10px;
        font-weight: 500;
        margin-top: 2px;
    }
    .a {
        fill: var(--main);
        color: var(--main);
    }


    .slide {

    }
    .main {
        /* margin-top: -20px; */
        /* background-color: #edeef0; */
        height: calc(100vh - 110px);
        /* border-top-left-radius: 20px; */
        /* border-top-right-radius: 20px; */
        /* padding: 5px 0px; */
        /* padding: 20px; */
        box-sizing: border-box;
        overflow: auto;
    }
    .swiper {
        height: 100%;
        overflow-y: auto;
    }

    .info {
        padding: 20px;
        padding-top: 50px;
        text-align: center;
        font-size: 12px;
        fill: #333;
    }



    .loading {
        text-align: center;
        padding-top: 50px;
    }
    .spin {
        margin: 0 auto;
        width: 20px;
        height: 20px;
    }
</style>