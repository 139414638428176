<template>
    <div class="request" :class="[request.active ? 'r_active' : '']">
        <div class="flex">
            <div class="dp">
                <img :src="request.user.dp"/>
            </div>
            <div class="info">
                <div class="name">
                    {{ request.user.firstname }} {{ request.user.lastname }}
                    
                    <verified :size="'20px'" v-if="request.user.verified" :style="{'height': '20px'}"/>
                </div>
                <div class="f">
                    <div class="small">@{{ request.user.username }}</div>
                    <div>·</div>
                    <div class="small">{{ date }}</div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <loading v-if="approve_loading"/>
            <div class="btn" v-else @click="approve_chat()">Approve</div>
            <div class="btn trans" @click="open_request_message()">View Message</div>
            <div class="btn red">Decline</div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import moment from 'moment';

import verified from '../../icons/verified.vue';

import loading from '../loading.vue';
export default {
    props: ['request'],
    components: {
        verified, loading
    },
    data() {
        return {
            date: moment(this.request.created).fromNow(),

            approve_loading: false
        }
    },
    computed: {
        chat_approved() {
            return this.$root.$data.chat_approved
        }
    },
    watch: {
        chat_approved(val) {
            if (val.chat_id == this.request.chat_id) {
                this.approve_chat()
            }
        }
    },
    methods: {
        
        open_request_message() {
            this.$root.$data.pop = true
            this.$root.$data.pop_prop = {
                'request': this.request
            }
            this.$root.$data.pop_item = 'request_message'
        },


        approve_chat() {

            this.approve_loading = true

            let form = new FormData()
            form.append('chat_id', this.request.chat_id)

            this.$http.post('/chat/approve', form)
            .then(data => {
                let r = data.data

                if (r.success) {

                    let chat = {
                        'chat_id': this.request.chat_id,
                        'date': this.request.created,
                        'updated_at': new Date(r.updated_at).getTime(),
                        'initiator': this.request.initiator,
                        'user1': this.request.user1,
                        'user2': this.request.user2,
                        'is_approved': true,
                        'declined': false,
                        'declined_at': '0000-00-00',
                        'count': r.count,
                        'user': this.request.user1,
                        'new_messages': 0
                    }
                    
                    db.collection('chats').add(chat)
                    localStorage.setItem('chat', JSON.stringify(chat))

                    db.collection('chat_users').add(r.chat_user[0])

                    for(let message of r.chat_messages) {
                        message['time'] = new Date(message.date).getTime()

                        db.collection(this.request.chat_id).doc({message_id: message.message_id}).get()
                        .then(document => {
                            if (document == undefined) {
                                db.collection(this.request.chat_id).add(message)
                            }
                        })

                    }

                    this.send_message('info', 'Chat Approved', 'chat_approved')

                }
            })

        },
        send_message(type, text, value) {
            let form = new FormData()
            form.append('chat_id', this.request.chat_id)
            form.append('text', text)
            form.append('type', type)
            form.append('value', value)
            
            this.$http.post('/chat/message', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    let message = {
                        'date': r.date,
                        'chat_id': this.request.chat_id,
                        'count': r.count,
                        'type': type,
                        'value': r.value,
                        'text': text,
                        'sender': this.$root.$data.username,
                        'receiver': this.request.user1,
                        'message_id': r.message_id,
                        'sent': true,
                        'delivered': false,
                        'delivered_date': r.date,
                        'seen': false,
                        'seen_date': r.date,
                        'time': new Date(r.date).getTime(),
                        'notify': r.notify,
                        'meta': r.meta
                    }

                    this.$root.$data.new_message_sent = message

                    db.collection(this.request.chat_id).add(message).then(() => {
                        db.collection('chats').doc({'chat_id': this.request.chat_id}).update({
                            'updated_at': new Date(r.date).getTime(),
                            'count': r.count
                        })
                    })
                    
                        
                    setTimeout(() => {
                        this.approve_loading = false
                        this.$router.push('/chat/' + this.request.chat_id)

                        this.$emit('chat_approved')

                    }, 1000);
                }
            })
        }
    }
}
</script>

<style scoped>
    .request {
        background-color: white;
        margin: 15px;
        /* margin-bottom: 0px; */
        border-radius: 5px;
        /* overflow: hidden; */
        border: 1px solid #ddd;
    }
    .r_active {
        animation: shadow_ani 1s infinite linear;
    }
    @keyframes shadow_ani {
        0% {
            box-shadow: var(--trans) 0px 0px 0px;
        }
        50% {
            border-color: white;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            /* box-shadow: var(--trans) 0px 5px 15px; */
        }
        100% {
            box-shadow: var(--trans) 0px 0px 0px;
        }
    }
    .flex {
        display: flex;
        align-items: center;
        padding: 15px;
        gap: 15px;
    }
    .dp {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dp img {
        width: 50px;
    }
    .name {
        font-weight: 700;

        display: flex;
        align-items: center;
        gap: 10px;
        fill: var(--main);
    }
    .f {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
    .small {
    }



    .bottom {
        padding: 15px;
        padding-top: 5px;
        box-sizing: border-box;
        /* border-top: 1px solid #ddd; */
        display: grid;
        grid-template-columns: calc(30% - 10px) calc(40% - 10px) calc(30% - 10px);
        gap: 15px;
    }
    .btn {
        font-size: 12px;
        font-weight: 500;
        background-color: var(--main);
        color: white;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
    }
    .trans {
        color: var(--main);
        background-color: var(--trans);
    }
    .red {
        background-color: rgba(255, 99, 71, 0.2);
        color: tomato;
    }
</style>