<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" >

            <path d="M725-410.001v-120H605v-59.998h120v-120h59.999v120h120v59.998h-120v120H725Zm-365-82.308q-57.749 0-98.874-41.124-41.125-41.125-41.125-98.874 
            0-57.75 41.125-98.874 41.125-41.125 98.874-41.125 57.749 0 98.874 41.125 41.125 41.124 41.125 
            98.874 0 57.749-41.125 98.874-41.125 41.124-98.874 41.124ZM60.002-187.694v-88.922q0-29.384 
            15.96-54.422 15.962-25.038 42.654-38.5 59.308-29.077 119.654-43.615T360-427.691q61.384 0 
            121.73 14.538 60.346 14.538 119.654 43.615 26.692 13.462 42.653 38.5 15.962 25.038 15.962 
            54.422v88.922H60.001Z"/>

        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>